import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SectionHeader from './SectionHeader';
import Section from './Section';
import DateFormat from './DateFormat';


const rightStyle = {
    backgroundColor: '#eee',
    paddingLeft: window.innerWidth  > 736 ? '32px' : '12px'
};


const RightColumn = ({profile}) => {
    return (
        <Col md={8} style={rightStyle}>
        <Row>
                <SectionHeader header="Work Experience" color={profile.color.light}></SectionHeader>
                <Section>
                    <ul>
                    {profile.experience.map(e => {
                        return (
                           <li className="exp-block" key={e.timeStart}>
                               <h6><strong>{e.companyName},</strong>&nbsp;<em>{e.title}</em></h6>
                               <p className="date-range"><DateFormat date={e.timeStart} /> - <DateFormat date={e.timeEnd} /></p>
                               <ul>
                                   {e.responsibilities.map(r => {
                                       return (
                                           <li key={r}>{r}</li>
                                       )
                                   })}
                               </ul>
                           </li>
                        )
                    })}
                    </ul>
                </Section>
            </Row>
            <Row>
                <SectionHeader header="Skills" color={profile.color.light}></SectionHeader>
                <Section>
                    <ul>
                    {profile.skills.map(skill => {
                        return (
                           <li key={skill}>{skill}</li>
                        )
                    })}
                    </ul>
                </Section>
            </Row>
          
        </Col>
    )
}
export default RightColumn;