// import './Header.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {  useRef, useEffect, useState } from "react";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";


const HeaderNew = ({profile}) => {
    const ref = useRef(null);
    const { width } = useContainerDimensions(ref)
    const [fontSize, setFontSize] = useState({hero: "5vw", sub: '3vw'});

    useEffect(() => {
        const size = window.innerWidth > window.innerHeight ? {hero: "5vw", sub: "3vw"} : {hero: "7vw", sub: "5vw"}
        setFontSize(size)
    }, [width])

    const style = {
        background: `linear-gradient(159deg,  ${profile.color.dark} 0%,  ${profile.color.light} 48%)`,
        color: '#fff',
        padding: '1em',
        borderBottom: `4px solid ${profile.color.dark}`,
    }
    const heroStyle = {
        fontSize: fontSize.hero,
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#fff',
        letterSpacing: '-1px',
        textShadow: '0px 1px 3px rgb(0 0 0 / 80%), 0px 4px 6px rgb(0 0 0 / 60%)',
        whiteSpace: 'nowrap',
        padding: '1%'

    }
    const subStyle = {
        fontSize: fontSize.sub,
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#fff',
        marginTop: '-1em',
        letterSpacing: '-1px',
        textShadow: '0px 1px 3px rgb(0 0 0 / 80%), 0px 4px 6px rgb(0 0 0 / 60%)',
        whiteSpace: 'nowrap',
        padding: '1%'

    }
    const picStyle = {
        borderRadius: '50%',
        boxShadow: '0 1.6px 3.6px 0 rgb(0 0 0 / 43%), 0 0.3px 0.9px 0 rgb(0 0 0 / 41%)',
        height: '220px', width: '220px'
    }

    return (
        <Container style={style}>
            <Row>
                <Col md={4} className="my-auto center">
                   <img src="me.jpg" alt={profile.name} style={picStyle} />
                </Col>
                <Col md={8} className="my-auto center" ref={ref}>
                    <p style={heroStyle} className="playfair">{profile.name}</p>
                    <p style={subStyle} className="economica">{profile.title}</p>
                </Col>
            </Row>
        </Container>
    )
}
export default HeaderNew;