
const Section = (props) => {
    const style = {
        padding: '0 1.2em 1em 1.2em'
    }
    return (
        <div style={style}>
            {props.children}
        </div>
    )
}
export default Section;