const DateFormat = ({date}) => {
    const months = {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        "10": "October",
        "11": "November",
        "12": "December",
        "present": "Present"
    }
    const formatted = date.split("-");

    return (
        <span>
            {formatted.length > 1 ? months[formatted[0]] + " " + formatted[1] : months[formatted[0]] }
        </span>
    )
}
export default DateFormat;