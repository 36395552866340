import './MainPage.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';

const MainPage = ({profile}) => {




    return (
        <Container className='main-page'>
            <Row>
            <LeftColumn profile={profile} />
            <RightColumn profile={profile} />
            </Row>
        </Container>
    )
}
export default MainPage;