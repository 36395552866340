import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DateFormat from './DateFormat';
import SectionHeader from './SectionHeader';
import Section from './Section';

const leftStyle = {
    backgroundColor: '#fff',
};
const contactStyle = {
    listStyle: 'none',
    margin: '6px -24px'
};
const iconStyle = {
    height: '24px',
    width: '24px',
    margin: '2px'
}

const LeftColumn = ({profile}) => {
    const linkStyle = {
        color: profile.color.light
    };
    return (
        <Col md={4} style={leftStyle}>
                <Row>
                    <SectionHeader header="About" color={profile.color.dark}></SectionHeader>
                    <Section>{profile.about}</Section>
                </Row>
               
                <Row>
                    <SectionHeader header="Education" color={profile.color.dark}></SectionHeader>
                    <Section>
                        {profile.education.map(e => {
                            return (
                                <div key={e.endDate}>
                                <strong>{e.school} - <em>{e.city}</em></strong>
                                <p>{e.degree} - <DateFormat date={e.endDate}></DateFormat></p>
                                </div>
                            )
                        })}
                    </Section>
                </Row>
                <Row>
                    <SectionHeader header="Certifications" color={profile.color.dark}></SectionHeader>
                    <Section>
                        <ul>
                        {profile.certifications.map(cert => {
                            return (
                               <li key={cert}>{cert}</li>
                            )
                        })}
                        </ul>
                    </Section>
                </Row>
                <Row>
                    <SectionHeader header="Contact" color={profile.color.dark}></SectionHeader>
                    <Section>
                        <ul>
                            <li key={1} style={contactStyle}><img alt="addressicon" src="address.png" style={iconStyle} />{profile.address.line1}<br />{profile.address.line2}</li>
                            <li key={2} style={contactStyle}><img alt="phoneicon" src="phone.png" style={iconStyle} />{profile.phone}</li>
                            <li key={3} style={contactStyle}><img alt="emailicon" src="email.png" style={iconStyle} /><a style={linkStyle} href={`mailto:${profile.email}`}>{profile.email}</a></li>
                            <li key={4} style={contactStyle}><img alt="linkedInicon" src="linkedIn.png" style={iconStyle} /><a style={linkStyle} href={profile.linkedIn}>{profile.linkedIn}</a></li>
                        </ul>
                    </Section>
                </Row>
                
            </Col>
    )
}
export default LeftColumn;