
const SectionHeader = ({header, color}) => {
    const textStyle = {
        textTransform: 'uppercase',
        color: color,
        letterSpacing: '3px',
        fontSize: '1.4em'
    }
    const underStyle = {
        width: '40%',
        borderBottom: '4px solid ' + color
    }
    return (
        <div className='section-header'>
            <p style={textStyle}>{header}</p>
            <div style={underStyle}></div>
        </div>
    )
}
export default SectionHeader;