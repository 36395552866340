import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainPage from './Components/MainPage/MainPage';
import HeaderNew from './Components/Header/HeaderNew';
import { Container } from 'react-bootstrap';

const profile = require('./Data/profile.json');
window.watsonAssistantChatOptions = {
  integrationID: "038a3357-612e-45a9-b98a-d744016eff24", // The ID of this integration.
  region: "us-east", // The region your integration is hosted in.
  serviceInstanceID: "ce114c58-b81d-4eaf-b66c-062deabe99f8", // The ID of your service instance.
  onLoad: function(instance) { instance.render(); }
};
setTimeout(function(){
  const t=document.createElement('script');
  t.src="https://web-chat.global.assistant.watson.appdomain.cloud/versions/" + (window.watsonAssistantChatOptions.clientVersion || 'latest') + "/WatsonAssistantChatEntry.js";
  document.head.appendChild(t);
});

function App() {
  return (
    <Container >
        <HeaderNew profile={profile} />
        <MainPage profile={profile} />
    </Container>

  );
}

export default App;
